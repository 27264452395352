:root {
  --dark-accent: #8e793e;
  --light-accent: #ad974f;
  --dark-color: #231f20;
  --light-color: #eaeaea;
}

* {
  margin: 0;
  padding: 0;
}

.html {
  min-height: 100vh;
}

.component-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(27, 24, 25);
  background: linear-gradient(
    90deg,
    rgba(27, 24, 25, 1) 0%,
    rgba(35, 31, 32, 1) 50%,
    rgba(27, 24, 25, 1) 100%
  );
}

.gold-text {
  color: var(--light-accent);
}

.light-background {
  background-color: var(--light-color);
}

.light-text {
  color: var(--light-color);
}

.dark-background {
  background-color: var(--dark-color);
}

.dark-text {
  color: var(--dark-color);
}

/* Footer */
.accent-footer {
  height: 40px;
  background: rgb(126, 104, 40);
  background: linear-gradient(
    90deg,
    rgba(126, 104, 40, 1) 0%,
    rgba(202, 179, 101, 1) 50%,
    rgba(126, 104, 40, 1) 100%
  );
  -webkit-box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.6);
}

#footer {
  margin-top: auto;
  width: 100%;
  height: 6%;
}

.footerSpan {
  line-height: 60px;
}

/* Navigation Bar */
nav.navbar {
  margin: 0 3%;
}

.nav-bar {
  height: 70px;
}

.accent-nav {
  height: 40px;
  background: rgb(126, 104, 40);
  background: linear-gradient(
    90deg,
    rgba(126, 104, 40, 1) 0%,
    rgba(202, 179, 101, 1) 50%,
    rgba(126, 104, 40, 1) 100%
  );
  -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.6);
}

.home-link {
  color: var(--light-accent) !important;
}

@media (max-width: 991px) {
  .nav-menu-top {
    position: relative;
  }

  .home-link {
    width: 100%;
    text-align: center;
    margin: 0 !important;
  }

  button.nav-button.navbar-toggler {
    position: absolute;
    right: 0;
  }

  .nav-menu-items {
    text-align: center;
  }

  a.nav-link {
    margin-left: 0 !important;
  }

  a.nav-link.closed-nav {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .nav-wrapper {
    height: 110px;
    transition: all 0.3s ease-in-out;
  }

  .open-nav {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 220px;
  }

  .collapsing {
    -webkit-transition: all 0.2s !important;
    -moz-transition: all 0.2s !important;
    -o-transition: all 0.2s !important;
    transition: all 0.2s !important;
  }
}

/* Form */
.form-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--dark-color);
  width: 100%;
  border: 4px solid var(--light-accent);
  border-radius: 15px;
  padding: 1.5rem 0 0 0;
}

.contact-header {
  display: none;
}

.contact-header-mob {
  font-size: 7.5vw;
  text-align: center;
  margin-bottom: 460px;
}

.form-text {
  color: var(--light-accent);
  width: 85vw;
  text-align: center;
  font-size: 5.5vw;
  line-height: 3vw;
}

.social-buttons {
  margin-top: -15px;
}

.form-ribbon {
  position: absolute;
  width: calc(100% + 28px);
  height: 416px;
  background-color: var(--light-color);
  left: -14px;
  top: 10%;
  border-radius: 4px 0px 4px 0px;
}

.form-ribbon:before {
  content: "";
  position: absolute;
  top: -6px;
  right: 0px;
  border-top: 2px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 4px solid #c4c4c4;
  border-left: 4px solid #c4c4c4;
}

.form-ribbon:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0px;
  border-top: 4px solid #c4c4c4;
  border-right: 4px solid #c4c4c4;
  border-bottom: 4px solid transparent;
  border-left: 6px solid transparent;
}

.contact-form {
  position: absolute;
  width: 80%;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  color: var(--light-accent) !important;
  margin: 25px 15px;
  font-size: 45px !important;
}

.message-input {
  height: 90px;
  line-height: 18px;
  padding: 3px 5px;
}

input {
  padding-left: 5px;
}

.send-button {
  background: rgb(189, 166, 93);
  background: linear-gradient(
    90deg,
    rgba(189, 166, 93, 1) 0%,
    rgba(240, 216, 140, 1) 50%,
    rgba(189, 166, 93, 1) 100%
  );
  font-weight: 600;
  border: 1px solid var(--dark-color);
  color: var(--dark-color);
}

@media (min-width: 400px) {
  .form-text {
    width: 80vw;
    font-size: 5vw;
  }

  .form-ribbon {
    top: 11.5%;
  }
}

@media (min-width: 500px) {
  .form-container {
    margin: 3vw 0;
  }
  .contact-header-mob {
    font-size: 40px;
  }

  .form-ribbon {
    top: 12.5%;
  }

  .form-text {
    font-size: 24px;
    line-height: 12px;
  }
}

@media (min-width: 750px) {
  .form-container {
    height: 400px;
    width: 700px;
  }

  .contact-header {
    display: block;
    margin: 30px 0;
  }

  .social-buttons {
    margin-top: -10px;
  }

  .contact-header-mob {
    display: none;
  }

  .form-ribbon {
    position: absolute;
    width: 300px;
    height: 416px;
    background-color: var(--light-color);
    top: -12px;
    left: 340px;
    border-radius: 4px 0px 4px 0px;
  }

  .form-text {
    position: absolute;
    color: var(--light-accent);
    width: 250px;
    top: 20px;
    left: 50px;
    text-align: center;
    font-size: 22px;
    line-height: 12px;
  }

  .form-ribbon:before {
    content: "";
    position: absolute;
    top: 0;
    right: -8px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #c4c4c4;
    border-left: 4px solid #c4c4c4;
  }

  .form-ribbon:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -8px;
    border-top: 4px solid #c4c4c4;
    border-right: 4px solid #c4c4c4;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .contact-form {
    position: absolute;
    width: 250px;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon {
    color: var(--light-accent) !important;
    margin: 25px 15px;
    font-size: 45px !important;
  }

  .message-input {
    height: 90px;
    line-height: 18px;
    padding: 3px 5px;
  }

  input {
    padding-left: 5px;
  }

  .send-button {
    background: rgb(189, 166, 93);
    background: linear-gradient(
      90deg,
      rgba(189, 166, 93, 1) 0%,
      rgba(240, 216, 140, 1) 50%,
      rgba(189, 166, 93, 1) 100%
    );
    font-weight: 600;
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
  }
}

@media (min-width: 992px) {
  .form-container {
    height: 430px;
    width: 850px;
    transform: skew(-7deg);
  }

  .form-ribbon {
    width: 400px;
    height: 446px;
    left: 370px;
  }

  .form-text {
    top: 35px;
    left: 58px;
  }

  .contact-form {
    width: 300px;
  }

  .message-input {
    height: 120px;
    resize: none;
  }
}

/* About */
.about-container {
  width: 100%;
  border: 4px solid var(--light-accent);
  border-radius: 15px;
  padding: 25px 2vw;
  margin: auto;
}

.about-wrapper {
  width: 95%;
  margin: auto;
}

.about-header {
  font-size: 7.5vw;
}

.skills-header {
  font-size: 5vw;
}

.paragraph-font {
  font-size: 4.4vw;
  text-align: justify;
}

.unordered-list li {
  font-size: 2.2vw;
  margin: 10px 10px 0px 10px;
  text-align: justify;
}

.unordered-list {
  display: none;
}

.unordered-list-mob {
  display: none;
}

.unordered-list-mob-columns {
  padding-left: 10px;
  margin: 15px auto 0;
}

.unordered-list-smallest {
  display: flex;
  padding-left: 10px;
  font-size: 4.5vw;
}

@media (min-width: 400px) {
  .about-header {
    font-size: 5.5vw;
  }

  .skills-header {
    font-size: 4.4vw;
  }

  .paragraph-font {
    font-size: 3.3vw;
    text-align: justify;
  }

  .unordered-list-smallest {
    display: none;
  }

  .unordered-list-mob {
    display: flex;
    font-size: 3vw;
  }
}

@media (min-width: 600px) {
  .about-container {
    width: 90%;
  }

  .about-wrapper {
    width: 90%;
  }

  .about-header {
    font-size: 4.3vw;
  }

  .skills-header {
    font-size: 3.2vw;
  }

  .paragraph-font {
    font-size: 2.3vw;
  }

  .unordered-list {
    display: block;
  }

  .unordered-list-mob {
    display: none;
  }
}

@media (min-width: 992px) {
  .about-container {
    width: 850px;
    padding: 20px;
    transform: skew(-7deg);
  }

  .about-header {
    font-size: 40px;
  }

  .skills-header {
    font-size: 30px;
  }

  .paragraph-font {
    font-size: 20px;
    width: 700px;
    text-align: center;
  }

  .unordered-list li {
    font-size: 19px;
    margin: 10px 20px 0px 30px;
    text-align: justify;
  }
}

/* Home */
.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 4px solid var(--light-accent);
  border-radius: 15px;
  padding-top: 3vw;
  margin: auto;
}

.profile-mob {
  width: 95%;
  margin: -15vw 4% 0vw 1%;
}

.profile-picture {
  margin-top: -5vw;
  width: 100%;
  object-fit: cover;
}

.home-mob {
  text-align: center;
  margin: 2vw auto 0;
}

.home-mob-header {
  font-size: 4.5vw;
  margin-bottom: 8vw;
}

.home-tablet {
  display: none;
}

.home-header {
  padding-top: 4%;
  line-height: 0.6;
  margin-bottom: 5%;
  font-size: 4.4vw;
  text-align: center;
}

.home-text {
  margin: 15px 0px 20px;
  font-size: 3.4vw;
}

.home-right-section {
  margin: auto;
  padding: 0;
  text-align: center;
}

.white-button {
  font-size: 5vw;
  padding: 5px 0;
  width: 25vw;
  text-align: center;
  margin: 5vw 0;
  color: var(--light-color);
  background-color: var(--dark-color);
  border: 2px solid var(--light-color);
  border-radius: 2px;
  transition: background-color 0.5s ease-in;
}

.white-button:hover {
  color: var(--dark-color);
  background-color: var(--light-color);
  font-weight: 500;
}

.gold-button {
  font-size: 5vw;
  padding: 5px 0px;
  width: 25vw;
  text-align: center;
  margin: 5vw 2vw;
  color: var(--light-accent);
  background-color: var(--dark-color);
  border: 2px solid var(--light-accent);
  border-radius: 2px;
  transition: background-color 0.5s ease-in;
}

.gold-button:hover {
  color: var(--dark-color);
  background-color: var(--light-accent);
  font-weight: 500;
}

@media (min-width: 600px) {
  .home-wrapper {
    width: 90%;
    flex-direction: row;
  }

  .home-mob {
    display: none;
  }

  .home-tablet {
    display: block;
  }

  .home-right-section {
    padding: 0 3vw 6vw 0;
  }

  .home-header {
    font-size: 4vw;
    text-align: left;
  }

  .home-text {
    font-size: 3vw;
    text-align: left;
  }

  .white-button {
    font-size: 2.3vw;
    padding: 5px 0;
    width: 12vw;
    margin: 10px 1vw 0px 0px;
  }

  .gold-button {
    font-size: 2.3vw;
    padding: 5px 0px;
    width: 12vw;
    margin: 10px 1vw 0px 0px;
  }
}

@media (min-width: 992px) {
  .home-wrapper {
    height: 430px;
    width: 850px;
    transform: skew(-7deg);
    padding-top: 50px;
  }

  .profile-picture {
    margin: -110px 0 0 -10px;
    padding-top: 30px;
    width: 450px;
    object-fit: cover;
    transform: skew(7deg);
  }

  .home-header {
    padding-top: 20px;
    font-size: 45px;
    margin-bottom: 30px;
  }

  .home-text {
    margin: 15px 0px 20px;
    font-size: 29.5px;
    text-align: left;
  }

  .home-right-section {
    margin: 15px 15px 0 0;
  }

  .white-button {
    font-size: 20px;
    width: 110px;
    margin: 10px 10px 0px 0px;
  }

  .gold-button {
    font-size: 20px;
    width: 110px;
    margin: 10px 10px 0px 0px;
  }
}

/* Projects */
.project-wrapper {
  display: flex;
}

.link-position {
  margin: 125px -45px;
  z-index: 2;
  filter: drop-shadow(2px 2px 1px #000);
}

.switch-icon {
  color: var(--light-accent) !important;
  font-size: 50px !important;
}

.project-container {
  width: 88vw;
  height: 400px;
  border-radius: 15px;
  border: 4px solid var(--light-accent);
  transform: skew(0deg);
}

.project-title {
  color: var(--light-accent);
  text-align: center;
  font-size: 26px;
  margin: 25px;
}

.project-image {
  width: 100%;
  height: 100%;
  border: 1px solid var(--light-color);
  border-radius: 5px;
}

.project-ribbon {
  position: absolute;
  width: calc(100% + 28px);
  background-color: var(--light-color);
  left: -14px;
  border-radius: 2% 0px 2% 0px;
}

.project-ribbon:before {
  content: "";
  position: absolute;
  top: -6px;
  right: 0px;
  border-top: 2px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 4px solid white;
  border-left: 4px solid white;
}

.project-ribbon:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0px;
  border-top: 4px solid white;
  border-right: 4px solid white;
  border-bottom: 4px solid transparent;
  border-left: 6px solid transparent;
}

.project-description {
  position: absolute;
  color: var(--light-color);
  padding: 30px;
  text-align: justify;
  top: 180px;
  font-size: 13px;
}

.project-description-two {
  position: absolute;
  color: var(--light-color);
  padding: 30px;
  text-align: justify;
  top: 180px;
  font-size: 13px;
}

.project-description-three {
  position: absolute;
  color: var(--light-color);
  padding: 30px;
  text-align: justify;
  top: 180px;
  font-size: 13px;
}

.hyperlink {
  color: var(--light-accent);
}

a.hyperlink:hover {
  color: var(--dark-accent) !important;
  text-decoration: none;
}

@media (min-width: 280px) {
  .project-container {
    width: 260px;
    height: 410px;
  }

  .project-title {
    font-size: 22px;
  }

  .project-description {
    top: 218px;
    font-size: 13px;
  }

  .project-description-two {
    top: 205px;
    font-size: 12px;
  }

  .project-description-three {
    top: 210px;
    font-size: 12px;
  }
}

@media (min-width: 340px) {
  .project-container {
    width: 320px;
    height: 480px;
  }

  .link-position {
    margin: 141px -45px;
  }

  .project-description {
    top: 260px;
    font-size: 16px;
  }

  .project-description-two {
    top: 245px;
    font-size: 15px;
  }

  .project-description-three {
    top: 250px;
    font-size: 15px;
  }
}

@media (min-width: 400px) {
  .project-container {
    width: 350px;
    height: 500px;
  }

  .project-title {
    font-size: 25px;
  }

  .link-position {
    margin: 148px -45px;
  }

  .project-description {
    top: 268px;
    font-size: 18px;
  }

  .project-description-two {
    top: 251px;
    font-size: 17px;
  }

  .project-description-three {
    top: 258px;
    font-size: 17px;
  }
}

@media (min-width: 550px) {
  .project-container {
    width: 500px;
    height: 500px;
  }

  .link-position {
    margin: 168px -45px;
  }

  .project-title {
    margin: 15px;
  }

  .project-description {
    top: 295px;
    font-size: 20px;
  }

  .project-description-two {
    top: 291px;
    font-size: 19px;
  }

  .project-description-three {
    top: 290px;
    font-size: 20px;
  }
}

@media (min-width: 750px) {
  .project-container {
    width: 600px;
    height: 540px;
  }

  .project-title {
    font-size: 30px;
  }

  .link-position {
    margin: 188px -45px;
  }

  .project-description {
    top: 355px;
  }

  .project-description-two {
    top: 343px;
    font-size: 20px;
  }

  .project-description-three {
    top: 345px;
  }
}

@media (min-width: 992px) {
  .project-container {
    width: 800px;
    height: 600px;
  }

  .link-position {
    margin: 235px -45px;
  }

  .project-description {
    top: 455px;
  }

  .project-description-two {
    top: 443px;
  }

  .project-description-three {
    top: 435px;
  }
}

@media (min-width: 1250px) {
  .link-position {
    margin: 230px 80px;
  }

  .project-container {
    transform: skew(-7deg);
  }
}
